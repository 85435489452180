<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,

  data() {
    return {
      chartData: {
        labels: this.date,
        datasets:
          this.data.length < 3
            ? [
                {
                  label: this.label ? this.label[0] : "",
                  borderColor: "#006edb",
                  fill: false, //라인 아래 배경 채우기
                  // backgroundColor: "rgba(0,0,0,0)",
                  tension: 0,
                  data: this.data[0],
                },
                {
                  label: this.label ? this.label[1] : "",
                  borderColor: "#009700",
                  fill: false,
                  tension: 0,
                  data: this.data[1],
                },
              ]
            : [
                {
                  label: this.label ? this.label[0] : "",
                  borderColor: "#006edb",
                  fill: false, //라인 아래 배경 채우기
                  // backgroundColor: "rgba(0,0,0,0)",
                  tension: 0,
                  data: this.data[0],
                },
                {
                  label: this.label ? this.label[1] : "",
                  borderColor: "#009700",
                  fill: false,
                  tension: 0,
                  data: this.data[1],
                },
                {
                  label: this.label ? this.label[2] : "",
                  borderColor: "#9c27b0",
                  fill: false,
                  tension: 0,
                  data: this.data[2],
                },
              ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                // min: 1000,
                // stepSize: 1000,
                fontSize: 12,
                fontColor: "#182933",
                fontWeight: 700,
                userCallback: function (value) {
                  value = value.toString();
                  value = value.split(/(?=(?:...)*$)/);
                  value = value.join(",");
                  return value;
                },
              },
              gridLines: { display: true },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 14,
                fontColor: "#182933",
                reverse: true,
              },
              gridLines: { display: false },
            },
          ],
        },
        legend: {
          // 범례
          display: this.label ? true : false,
          position: "bottom",
          labels: {
            boxWidth: 30,
            padding: 30,
          },
        },
        layout: {
          padding: {
            bottom: 20,
            left: 30,
            right: 30,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          // duration: 0, // 차트 생성시 애니메이션
        },
      },
    };
  },
  props: {
    date: {
      type: Array,
      default: () => [],
      description: "date label",
    },
    data: {
      type: Array,
      default: () => [],
      description: "date label",
    },
    label: Array,
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
    // date() {
    //   this.$data._chart.destroy();
    //   this.renderLineChart();
    // },
  },
  created() {},
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    renderLineChart() {
      this.renderChart(
        {
          labels: this.date,
          datasets: [
            {
              label: this.label ? this.label[0] : "",
              borderColor: "#006edb",
              fill: false, //라인 아래 배경 채우기
              // backgroundColor: "rgba(0,0,0,0)",
              tension: 0,
              data: this.value,
            },
            {
              label: this.label ? this.label[1] : "",
              borderColor: "#009700",
              fill: false,
              tension: 0,
              data: this.value2,
            },
            {
              label: this.label ? this.label[2] : "",
              borderColor: "#9c27b0",
              fill: false,
              tension: 0,
              data: this.value3,
            },
          ],
        },
        this.options
      );
    },
  },
};
</script>
